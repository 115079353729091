<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeEditView"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeEditView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Edytowanie pracownika: {{ userToEdit.name }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark color="green" @click="validateUpdateUser">
              Zapisz
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="mt-8">
          <v-row justify="center">
            <v-col cols="12" md="3">
              <v-text-field
                v-model="email"
                ref="email"
                label="Email"
                clearable
                :loading="loading"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="name"
                ref="name"
                label="Nazwa"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-select
                v-model="activeSelect"
                ref="active"
                :items="selectItems"
                item-text="text"
                item-value="isActive"
                return-object
                label="Aktywność"
                :loading="loading"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "UserEdit",

  data() {
    return {
      rules: {
        required: (value) => !!value || "Wymagane.",
        min: (v) => v.length >= 6 || "Min 6 znaków",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Błędny email.";
        },
      },
      dialog: false,
      formHasErrors: false,
      email: "",
      name: "",
      role: "",
      loading: false,
      activeSelect: {
        isActive: 1,
        text: "Tak",
      },
      selectItems: [
        {
          isActive: 1,
          text: "Tak",
        },
        {
          isActive: 0,
          text: "Nie",
        },
      ],
      // roleSelect: {
      //   roleName: "standard",
      //   text: "Standardowy",
      // },
      // roleItems: [
      //   {
      //     roleName: "standard",
      //     text: "Standardowy",
      //   },
      //   {
      //     roleName: "admin",
      //     text: "Administrator",
      //   },
      // ],
    };
  },
  computed: {
    form() {
      return {
        email: this.email,
        name: this.name,
        // role: this.roleSelect,
        active: this.activeSelect,
      };
    },
  },
  watch: {
    userEditView(newVal) {
      this.dialog = newVal;
      this.email = this.userToEdit.email;
      this.name = this.userToEdit.name;
      this.activeSelect = this.selectItems.filter((el) => {
        return el.isActive == this.userToEdit.active;
      })[0];
      // this.roleSelect = this.roleItems.filter((el) => {
      //   return el.roleName == this.userToEdit.role;
      // })[0];
    },
  },
  props: {
    userToEdit: {
      required: true,
    },
    userEditView: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeEditView() {
      this.$emit("closeEditView");
    },
    validateUpdateUser() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.updateUser();
      }
    },
    updateUser() {
      this.loading = true;
      let user = {
        id: this.userToEdit.id,
        email: this.email,
        name: this.name,
        // role: this.roleSelect.roleName,
        active: this.activeSelect.isActive,
      };
      this.$emit("updateUser", user);
      this.closeEditView();
      this.loading = false;
    },
  },
};
</script>