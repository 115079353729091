<template>
  <section class="cms-users">
    <CMSNavTop :apiLink="apiLink" />
    <CMSNavSidebar />
    <div style="padding-left: 56px">
      <v-container fluid>
        <Alert
          :alertShow="alertShow"
          :alertText="alertText"
          :alertType="alertType"
          @closeAlert="closeAlert"
        />
        <v-row>
          <v-col class="mt-3"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                ><v-btn
                  @click.prevent="showCreate"
                  dark
                  block
                  color="green"
                  x-large
                  >Dodaj nowego pracownika
                  <v-icon>mdi-account-plus-outline</v-icon></v-btn
                ></v-card-text
              ></v-card
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col>
            <UsersTable
              :users="users"
              :userTableLoading="userTableLoading"
              @showEdit="showEdit"
              @toogleUserActive="toogleUserActive"
            />
          </v-col>
        </v-row>
        <UserEdit
          :userEditView="userEditView"
          :userToEdit="userToEdit"
          @closeEditView="closeEditView"
          @updateUser="updateUser"
        />
        <UserCreate
          :userCreateView="userCreateView"
          :apiLink="apiLink"
          @closeCreateView="closeCreateView"
          @getUsers="getUsers"
          @showAlert="showAlert"
        />
      </v-container>
    </div>
  </section>
</template>

<script>
import CMSNavTop from "@/components/CMS/Navigations/CMSNavTop.vue";
import CMSNavSidebar from "@/components/CMS/Navigations/CMSNavSidebar.vue";
import Alert from "@/components/Alert.vue";
import UserEdit from "@/components/CMS/Users/UserEdit.vue";
import UsersTable from "@/components/CMS/Users/UsersTable.vue";
import UserCreate from "@/components/CMS/Users/UserCreate.vue";

export default {
  name: "CMSUsers",
  components: {
    CMSNavTop,
    CMSNavSidebar,
    Alert,
    UserEdit,
    UsersTable,
    UserCreate,
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userEditView: false,
      userCreateView: false,
      alertText: "",
      alertType: "success",
      alertShow: false,
      users: [],
      userTableLoading: false,
      userToEdit: {},
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    showEdit(userToEdit) {
      this.userEditView = true;
      this.userToEdit = userToEdit;
    },
    showCreate() {
      this.userCreateView = true;
    },
    closeEditView() {
      this.userEditView = false;
    },
    closeCreateView() {
      this.userCreateView = false;
    },
    showAlert(alertText, alertType) {
      this.alertShow = true;
      this.alertText = alertText;
      this.alertType = alertType;
      console.log(alertText);
    },
    closeAlert() {
      this.alertShow = false;
    },
    toogleUserActive(user) {
      this.userTableLoading = true;
      this.updateUser(user);
      this.userTableLoading = false;
    },
    async getUsers() {
      this.userTableLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/users/getUsers.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.users = res.data;
          } else {
            this.$emit(
              "showAlert",
              `Błąd podczas pobierania pracowników. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.showAlert(`Błąd pobierania firm. Błąd: ${err}`, "error");
        });
      this.userTableLoading = false;
    },
    async updateUser(user) {
      console.log(user);
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/users/updateUser.php`,
        data: {
          token: localStorage.token,
          user,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.showAlert("Zmiany zostały zapisane", "success");
            } else {
              this.showAlert(
                `Błąd podczas edytowania firmy. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.getUsers();
          }
        })
        .catch((err) => {
          this.showAlert(
            `Błąd podczas edytowania firmy. Zmiany nie zostały zapisane. Błąd: ${err}`,
            "error"
          );
        });
    },
  },
};
</script>