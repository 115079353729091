<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeCreateView"
    >
      <v-card ref="form">
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeCreateView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Dodawanie nowego pracownika</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark color="green" @click="validateCreateUser">
              Dodaj
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="mt-8">
          <v-row justify="center">
            <v-col cols="12" md="2">
              <v-text-field
                v-model="email"
                ref="email"
                label="Email"
                clearable
                :loading="loading"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="password"
                ref="password"
                label="Hasło"
                :loading="loading"
                :type="showpassword ? 'text' : 'password'"
                counter
                :rules="[rules.required, rules.counter, rules.min]"
                :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                append-outer-icon="mdi-lock-plus"
                @click:append="showpassword = !showpassword"
                @click:append-outer="passwordGenerator"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="3">
              <v-text-field
                v-model="name"
                ref="name"
                label="Nazwa"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
              <v-select
                v-model="activeSelect"
                ref="active"
                :items="selectItems"
                item-text="text"
                item-value="isActive"
                return-object
                label="Aktywność"
                :loading="loading"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "UserCreate",

  data() {
    return {
      rules: {
        required: (value) => !!value || "Wymagane.",
        min: (v) => v.length >= 6 || "Min 6 znaków",
        counter: (value) => value.length <= 20 || "Max 20 znaków",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Błędny email.";
        },
      },
      dialog: false,
      formHasErrors: false,
      email: "",
      password: "",
      showpassword: false,
      name: "",
      role: "",
      loading: false,
      activeSelect: {
        isActive: 1,
        text: "Tak",
      },
      selectItems: [
        {
          isActive: 1,
          text: "Tak",
        },
        {
          isActive: 0,
          text: "Nie",
        },
      ],
      // roleSelect: {
      //   roleName: "standard",
      //   text: "Standardowy",
      // },
      // roleItems: [
      //   {
      //     roleName: "standard",
      //     text: "Standardowy",
      //   },
      //   {
      //     roleName: "admin",
      //     text: "Administrator",
      //   },
      // ],
    };
  },
  computed: {
    form() {
      return {
        email: this.email,
        password: this.password,
        name: this.name,
        // role: this.roleSelect,
        active: this.activeSelect,
      };
    },
  },
  watch: {
    userCreateView(newVal) {
      this.dialog = newVal;
    },
  },
  props: {
    userCreateView: {
      type: Boolean,
      required: true,
    },
    apiLink: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeCreateView() {
      this.$emit("closeCreateView");
    },
    passwordGenerator() {
      var length = 10,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.password = retVal;
    },
    async createUser() {
      let user = {
        email: this.email,
        password: this.password,
        name: this.name,
        // role: this.roleSelect.roleName,
        active: this.activeSelect.isActive,
      };

      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/users/createUser.php`,
        data: {
          token: localStorage.token,
          user,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.$emit("showAlert", "Pracownik został dodany.", "success");
            } else {
              this.$emit(
                "showAlert",
                `Błąd podczas dodawania pracownika. Pracownik nie został dodany. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.$emit("getUsers");
          } else {
            this.$emit(
              "showAlert",
              `Błąd podczas dodawania pracownika. Pracownik nie został dodany. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.$emit(
            "showAlert",
            `Błąd podczas dodawania pracownika. Pracownik nie został dodany. Błąd: ${err}`,
            "error"
          );
        });
      this.closeCreateView();
      this.loading = false;
    },
    validateCreateUser() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.createUser();
      }
    },
  },
};
</script>