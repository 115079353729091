<template>
  <section>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Szukaj"
      single-line
      hide-details
      class="mb-3 elevation-1 pa-3"
      clearable
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :footer-props="{
        'items-per-page-text': 'Użytkowników na stronę',
        'items-per-page-options': [10, 20, 50, 100],
      }"
      :items-per-page="10"
      class="elevation-2"
      multi-sort
      :sort-by="['active', 'name']"
      :sort-desc="true"
      :loading="userTableLoading"
      loading-text="Wczytywanie... Proszę czekać"
      :header-props="headerProps"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.email }}</td>
          <td>{{ item.name }}</td>
          <td :class="item.active ? 'green--text' : 'red--text'">
            {{ item.active ? "Tak" : "Nie" }}
          </td>
          <td width="10px">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="warning"
                      @click.prevent="showEdit(item)"
                      >Edytuj</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      dark
                      color="red"
                      @click.prevent="toogleUserActive(item)"
                      >{{ item.active == 1 ? "Archiwizuj" : "Przywróć" }}</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }}-{{ items.pageStop }} z
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Email", value: "email" },
        { text: "Nazwa", value: "name" },
        { text: "Aktywność", value: "active" },
        { text: "Akcje", sortable: false },
      ],
      headerProps: {
        sortByText: "Sortowanie",
      },
    };
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    userTableLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    showEdit(item) {
      this.$emit("showEdit", item);
    },
    toogleUserActive(user) {
      user.active = !user.active;
      this.$emit("toogleUserActive", user);
    },
  },
};
</script>